import React, { Component } from 'react'
import { Responsive } from 'semantic-ui-react'
import config from '../config'

class Hero extends Component {
  state = { hatname : 'THINK'}
  componentDidMount = () => {
    let count = 0
    this.interval = setInterval(()=> {
        let hatname = config.hatnames
        this.setState({hatname : hatname[count]})
        if(count > hatname.length - 2){
            count = 0
        } else {
            count = count + 1
        }
    }, 1250)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
}
  showHat = () => {
    return (<u className="redunderlinesingle">{this.state.hatname}</u>)
  }

  render() {
    return (
      <div style={{ background: config.colors.primary, width: '100vw', marginTop:'100px' }}>
          <Responsive maxWidth={767}>
          <h1 className="hero"> Make America </h1>
          <h1 className="hero">
            {this.showHat()}  Again.{' '}
          </h1>
          </Responsive>
          <Responsive minWidth={768}>
          <h1 className="hero fullpage"> Make America </h1>
          <h1 className="hero fullpage">
         
            {this.showHat()} Again.{' '}
          </h1>
          </Responsive>
      </div>
    )
  }
}

export default Hero
