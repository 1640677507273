import React from 'react'
import { Link } from 'gatsby'
import { Grid, Divider, Container } from 'semantic-ui-react'
import HatImage from './hatImage'

const HatStore = props => {
  const images = props.data
  const pages = props.pages

  return (
    <div>
      <h1  className="wtfHeader">Make America HATS Again!</h1>
      <Container style={{paddingBottom: '-180px', marginBottom: '-80px'}} textAlign='justified'>
      
          If you're looking for a made in America hat, this is it! Our hat's are low profile,
          unstructured with a curved visor and adjustable strap. There's also a
          small American flag sewed onto the back.
        </Container>
        <div id='hatstore' style={{paddingTop: '120px'}}>
      <Grid centered columns={4} doubling>
        {pages.map((page, key) => {
          // find the image based on the title, images are named NameColor.png
          let hatImage = images.filter(hat => {
            let title =
              page.node.context.title.charAt(0).toUpperCase() +
              page.node.context.title.slice(1).toLowerCase()
            let matcher = new RegExp(title, 'g')
            return matcher.test(hat.node.fluid.originalName)
          })

          // create card for each hat design
          return (
            <Grid.Column key={key}>
              <Link to={'/hats/' + pages[key].node.context.title.toLowerCase()}>
                  <HatImage images={hatImage} title={page.node.context.title} />                 
              </Link>
              <Divider/>
            </Grid.Column>
          )
        })}
      </Grid>
      </div>
    </div>
  )
}
export default HatStore
