import React, { Component } from 'react'
import Img from 'gatsby-image'
import config from '../config'

export default class HatImage extends Component {
  state = { color: 1 }
  changeBlue = () => {
    this.setState({ color: 0 })
  }

  changeWhite = () => {
    this.setState({ color: 1 })
  }

  render() {
    let color = this.state.color
    return (
      <div
        onMouseOver={() => this.changeBlue()}
        onMouseLeave={() => this.changeWhite()}
      >
        <div className="basicCard">
          <h1 style={{padding: '20px', fontSize: '1.5rem'}} className="redunderline">{this.props.title}</h1>

          <Img fluid={this.props.images[color].node.fluid} />

          <h2 style={{padding: '20px', fontSize: '1.5rem'}} className="redunderline">${(config.price / 100).toFixed(2)}</h2>
        </div>
      </div>
    )
  }
}
