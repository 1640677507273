import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../layouts/product'

import Hero from '../components/hero'
import { Grid, Container, Icon } from 'semantic-ui-react'
import HatStore from '../components/hatStore'
import config from '../config.jsx'

const IndexPage = ({ data }) => (
  <div>
    <Hero />
    <Layout>
      <Grid.Row
        style={{
          textAlign: 'center',
          margin: '-10px auto 20px',
        }}
      >
        <Grid.Column width={16}>
          
          <Link
            to="/#hatstore"
            style={{
              padding: '20px 60px',
              backgroundColor: config.colors.secondary,
              color: config.colors.tertiary
            }}
          >
            Find Your Hat Now {'  '}
            <Icon name='hand point down outline'/>
          </Link>
          
        </Grid.Column>
      </Grid.Row>
      <Grid columns={2} stackable>
        <Grid.Row>
          <Grid.Column width={16}>
            <Container style= {{marginTop: '30px', marginBottom: '30px'}} textAlign="justified">
              The last few years have seen a lot of turmoil and uncertainty.
              Once trusted establishments have been under attack from the very
              office they hold to account. Truth and Decency which were once
              held with such high regard have been thrown to the wayside. This
              next election is about getting America back on the right path and
              retaking it's place as a beacon to the rest of the world.
            </Container>
          </Grid.Column>
          
          
        </Grid.Row>
        <Grid.Row centered >
          <Grid.Column width={8}>
          <Img fluid={data.allImageSharp.edges[34].node.fluid} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>

          <Grid.Column width={16}>
          <h1 className='wtfHeader'>Join the Resistance!</h1>
            <Container style= {{marginTop: '30px', marginBottom: '30px'}} textAlign="justified">
              
              For that to happen, the People need to get out and vote,
              discussions about the real topics need to be had and the half
              truths and lies need to be settled. Make America Hats Again
              (MAHA), are designed to start a conversation, to raise awareness
              of the issues and to get America back on track. All our hats are
              100% made and embroidered in America by American workers.
            </Container>
          </Grid.Column>
        </Grid.Row>
        <HatStore
          data={data.allImageSharp.edges}
          pages={data.allSitePage.edges}
        />
      </Grid>
    </Layout>
  </div>
)

export default IndexPage

//sends optimized images and generated pages into hatstore component
export const query = graphql`
  query {
    allImageSharp {
      edges {
        node {
          fluid(traceSVG: { color: "#002147" }) {
            aspectRatio
            tracedSVG
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
    }
    allSitePage(filter: { context: { title: { ne: null } } }) {
      edges {
        node {
          context {
            id
            title
            description
          }
        }
      }
    }
  }
`
